import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Update() {
const[id, setId]=useState("");
const[name, setName]=useState("");
const[age, setAge]=useState("");
const[email, setEmail]=useState("");


  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault(); 
    axios
      .put(`https://65a4c33952f07a8b4a3da6e0.mockapi.io/crudApp/${id}`, {
        name: name,
        age: age,
        email: email,
      })
      .then(() => {
        // Update localStorage with the new data
        localStorage.setItem("id", id);
        localStorage.setItem("name", name);
        localStorage.setItem("age", age);
        localStorage.setItem("email", email);

        // Navigate to the home page
        navigate("/");
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  }

  useEffect(()=>{
   setId(localStorage.getItem('id'));
    setName(localStorage.getItem('name'));
    setAge(localStorage.getItem('age'));
    setEmail(localStorage.getItem('email'));


  },[])

  

  return (
    <>
      <div className="row">
        <div className=" d-flex justify-content-center mt-7 border border-3  ">
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center">
              <h4>Update Data</h4>
            </div>
            <div>
              <label>Update Name</label>
              <input
                type="text"
                placeholder="Updated Name"
                className="form-control"
               value={name}
                name="name"
                onChange={(e)=>{setName(e.target.value)}}
               
                // required
              />
            </div>
            <br />
            <div>
              <label>Update Age</label>
              <input
                type="number"
                placeholder="Update Age"
                className="form-control"
                name="age"
                value={age}
                onChange={(e)=>{setAge(e.target.value)}}
                // required
                
              />
            </div>
            <br />
            <div>
              <label>Update Email</label>
              <input
                type="email"
                placeholder="Update Email"
                className="form-control"
                name="email"
                value={email}
                onChange={(e)=>{setEmail(e.target.value)}}
                // required

              />
            </div>
            <br />
            <div className="d-grid ">
              <input type="submit" value="Update Data" className="btn btn-primary" />
              <br />
            </div>
          </form>
        </div>

        <div className="d-grid ">
          <br /> <br />
          <input
            type="submit"
            value="Show Data List"
            className="btn btn-primary"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Update;