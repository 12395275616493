import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function Create() {
  const [data, setData] = useState({
    name: "",
    age: "",
    email: ""
  });

  // const { name, age, email } = data; 

  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://65a4c33952f07a8b4a3da6e0.mockapi.io/crudApp', {
      name: data.name,
      age: data.age,
      email: data.email
    }).then(() => {
      navigate('/');
    });
  };

  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-center mt-7 border border-3">
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center">
              <h4>Create Data</h4>
            </div>
            <div>
              <label>Enter Name</label>
              <input
                type="text"
                placeholder="Enter Name"
                className="form-control"
                name="name"
                value={data.name}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label>Enter Age</label>
              <input
                type="number"
                placeholder="Enter Age"
                className="form-control"
                name="age"
                value={data.age}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label>Enter Email</label>
              <input
                type="email"
                placeholder="Enter Email"
                className="form-control"
                name="email"
                value={data.email}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div className="d-grid">
              <input
                type="submit"
                value="Submit"
                className="btn btn-primary"
              />
              <br />
            </div>
          </form>
        </div>

        <div className="d-grid">
          <br /> <br />
          <input
            type="submit"
            value="Show Data List"
            className="btn btn-primary"
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Create;
