import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,

  TableContainer,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import  {Box}  from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Read() {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const info = () => {
    axios
      .get("https://65a4c33952f07a8b4a3da6e0.mockapi.io/crudApp")
      .then((response) => setData(response.data));
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://65a4c33952f07a8b4a3da6e0.mockapi.io/crudApp/${id}`)
      .then(() => {
        info();
      });
  };

  const setDataStorage = (id,name,age,email)=>{
    localStorage.setItem("id", id)
    localStorage.setItem("name", name)
    localStorage.setItem("age", age)
    localStorage.setItem("email", email)

  }

  useEffect(() => {
    info();
  }, []);
  console.log(data);

  return (
    <>
      <Box sx={{}}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, backgroundColor: "#e9f2e9" }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Age</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((element) => {
                return (
                  <TableRow>
                    <TableCell align="center">{element.id}</TableCell>
                    <TableCell align="center">{element.name}</TableCell>
                    <TableCell align="center">{element.age}</TableCell>
                    <TableCell align="center">{element.email}</TableCell>
                    <TableCell align="center">
                    <Link to="/update">
                    <Button variant="contained" color="primary" onClick={()=>setDataStorage(element.id,element.name,element.age,element.email)}>
                        UPDATE
                      </Button>
                    </Link>
                    
                    </TableCell>

                    <TableCell align="center">
                    
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          if (window.confirm("Are you sure to delete Data?")) {
                            handleDelete(element.id);
                          }
                        }}
                      >
                        DELETE
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/create");
          }}
        >
          Create New User
        </Button>
      </Box>
    </>
  );
}

export default Read;