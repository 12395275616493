// import Axios from "axios";
import "./App.css";
import Create from "./component/Create";
import Update from "./component/Update";
// import { useState } from "react";
import Read from "./component/Read";
import {Routes, Route} from "react-router-dom"

function App() {
  return (
    <div className="container">
      
    <Routes>
      <Route exact path="/" element={<Read />} />
      <Route exact path="/create" element={<Create />} />
      <Route exact path="/update" element={<Update/>}/>
    </Routes>

    </div>
  );
}

export default App;
